<script>
export default {
	name: "404"
}
</script>

<template>
	<div class="text-center" style="margin-top: 200px;">
		<div class="not-found m-auto">
			<h1 style="font-size: 100px;">404</h1>
			<h2>PAGE NOT FOUND</h2>
		</div>
		<div class="text-center pt-4">
			<a href="/profile" class="btn btn-primary">Go to profile</a>
		</div>
	</div>
</template>

<style scoped lang="scss">

</style>